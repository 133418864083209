<template>
  <div id="mapContainer" class="basemap"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import circle from "@turf/circle";

export default {
  name: "BaseMap",
  props: ["coordinates", "radius", "watchWholeRegion"],
  data() {
    return {
      map: null,
      zoomLevel: 8,
      turfCircle: null,
      renderedFeatureId: null,
    };
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
    this.map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/konoff/cls4t8wl5027o01nl026049ax",
      center: [this.coordinates.longitude, this.coordinates.latitude],
      zoom: 8,
      cooperativeGestures: true,
    });
    // new mapboxgl.Marker().setLngLat([this.coordinates.longitude, this.coordinates.latitude]).addTo(this.map);

    this.map.on('style.load', () => {
      const waiting = () => {
        if (!this.map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          console.log("Has loaded!");
          this.setupMapForRadius(this.radius);
          this.$emit('map-loaded');
        }
      };
      waiting();
    });


    this.map.on('idle', () => {
      console.log('idling..');
      if (this.renderedFeatureId) {
        return;
      }
      var map = this.map;
      let center = [this.coordinates.longitude, this.coordinates.latitude];
      let adminLayerBgId = 'admin1-ak';
      let features = this.map.queryRenderedFeatures(center, {layers: [adminLayerBgId]});

      this.renderedFeatureId = features[0].properties.id;

      this.map.addLayer({
          'id': 'active-region',
          'type': 'fill',
          'source': 'composite',
          'source-layer': "admin1-0i7gza",
          'filter': ['==', 'id', this.renderedFeatureId],
          'layout': {
              // Make the layer invisible by default.
              'visibility': 'none'
          },
          'paint': {
              'fill-color': 'rgba(255, 104, 44, 0.20)',
              'fill-outline-color': '#FF682C'
          }
      });

    });
  },
  watch: {
    radius: function(newVal, oldVal) {
      this.setupMapForRadius(newVal);
    },
    coordinates: function(newVal, oldVal) {
      console.log("New coordinates:" + newVal);
      this.setCenter(newVal);
    },
    watchWholeRegion: function(newVal, oldVal) {
      this.setupMapForRadius(this.radius);
    }
  },
  methods: {
    setupMapForRadius(radius) {
      if (this.watchWholeRegion) {
        // highlight the whole region
        // create a new source from the region feature
        this.map.setLayoutProperty('active-region', 'visibility', 'visible');

        // hide the turf circle
        if (this.turfCircle) {
          this.map.setLayoutProperty('circle-fill', 'visibility', 'none');
        }

        // get zoomin'
        let highlightedRegionFeatures = this.map.querySourceFeatures('composite', {
          sourceLayer: 'admin1-0i7gza',
          filter: ['==', 'id', this.renderedFeatureId]
        });

        if (highlightedRegionFeatures.length == 0) {
          return;
        }
        let bounds = this.getBoundingBox({features: highlightedRegionFeatures});
        this.map.fitBounds(bounds, {
          padding: 20
        });
      } else {
        // adjust the circle
        let radiusInt = parseInt(radius);
        // create big old circle
        if (this.turfCircle) {
          this.map.setLayoutProperty('circle-fill', 'visibility', 'visible');
          this.map.setLayoutProperty('active-region', 'visibility', 'none');

          this.turfCircle = this.createCircle(radiusInt);
          this.map.getSource('circleData').setData(this.turfCircle);
        } else {
          this.turfCircle = this.createCircle(radiusInt);
          this.map.addSource("circleData", {
            type: "geojson",
            data: this.turfCircle,
          });
          this.map.addLayer({
            id: "circle-fill",
            type: "fill",
            source: "circleData",
            'layout': {
              // Make the layer visible by default.
              'visibility': 'visible'
            },
            paint: {
              "fill-color": "rgba(255, 104, 44, 0.20)",
              "fill-outline-color": "#FF682C",
            },
          });
        }

        this.zoomToFit(this.turfCircle, this.map);
      }

    },
    zoomToFit(feature, map) {
      let coordinates = feature.geometry.coordinates.flat();
      let bounds = coordinates.reduce(function(bounds, coord) {
          return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
          padding: 20
      });
    },
    getBoundingBox(data) {
      let bounds = { xMin: Infinity, xMax: -Infinity, yMin: Infinity, yMax: -Infinity };

      // Iterate through each feature
      data.features.forEach(feature => {
        const { coordinates, type } = feature.geometry;

        // Flatten the coordinate structure based on the geometry type
        let allCoords = [];
        if (type === 'Polygon') {
          allCoords = coordinates.flat(); // Flatten once for Polygon
        } else if (type === 'MultiPolygon') {
          allCoords = coordinates.flat(2); // Flatten twice for MultiPolygon
        }

        // Iterate over the flattened coordinates to update bounds
        allCoords.forEach(coord => {
          const [longitude, latitude] = coord;
          if (longitude < bounds.xMin) bounds.xMin = longitude;
          if (longitude > bounds.xMax) bounds.xMax = longitude;
          if (latitude < bounds.yMin) bounds.yMin = latitude;
          if (latitude > bounds.yMax) bounds.yMax = latitude;
        });
      });

      // Check if bounds are set correctly
      if (bounds.xMin < Infinity && bounds.xMax > -Infinity && bounds.yMin < Infinity && bounds.yMax > -Infinity) {
        return new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(bounds.xMin, bounds.yMin),
          new mapboxgl.LngLat(bounds.xMax, bounds.yMax)
        );
      }
      return undefined;
    },
    createCircle(radius) {
      let center = [this.coordinates.longitude, this.coordinates.latitude];
      let options = { steps: 50, units: "meters"};
      let turfCircle = circle(center, radius, options);
      return turfCircle;
    },
    setCenter(newCenter) {
      this.map.setCenter([newCenter.longitude, newCenter.latitude]);
    }
  },
  computed: {
    zoomLevelFor: function() {
      return this.$store.state.phoneNumber != '';
    },
  }
};
</script>

<style lang="scss" scoped>
.basemap {
  width: 100%;
  height: 274px;
  margin: auto;
}
</style>